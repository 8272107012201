import firebase from 'firebase/app';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyA3-TLqY4hIqFxzTnYpIavU47sq-JclevQ',
  authDomain: 'j-sword-c87ea.firebaseapp.com',
  databaseURL: 'https://j-sword-c87ea.firebaseio.com',
  projectId: 'j-sword-c87ea',
  storageBucket: 'j-sword-c87ea.appspot.com',
  messagingSenderId: '442445161881',
  appId: '1:442445161881:web:44bac4ec232e030da3df08',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
